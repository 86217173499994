<template>
  <!--begin::Stats Widget 10-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
        <span class="symbol symbol-50 symbol-light-primary mr-2">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
              <inline-svg src="media/svg/icons/Shopping/Cart3.svg" />
              <!--end::Svg Icon-->
            </span>
          </span>
        </span>
        <div class="d-flex flex-column text-right">
          <!-- <span class="text-dark-75 font-weight-bolder font-size-h3">+259</span> -->
          <span class="text-muted font-weight-bold mt-2">Monthly Shipment Income</span>
        </div>
      </div>
      <!--begin::Chart-->
      <apexchart class="card-rounded-bottom" :options="chartOptions" :series="series" type="area" width="100%">
      </apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 10-->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [

      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
   this.getShipmentTrend();
  },
  methods: {
    getShipmentTrend() {
      ApiService.get("dashboardshipmenttrends")
        .then((response) => {
          if (response.data.success) {
            var data = response.data.trend;

            this.series.push({
              name: "Net Sales",
              data: data.trend,
            });
            this.chartOptions = {
              chart: {
                type: "area",
                height: 150,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
                sparkline: {
                  enabled: true,
                },
              },
              plotOptions: {},
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              fill: {
                type: "solid",
                opacity: 1,
              },
              stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: [this.layoutConfig("colors.theme.base.primary")],
              },
              xaxis: {
                categories: data.months,
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                labels: {
                  show: false,
                  style: {
                    colors: this.layoutConfig("colors.gray.gray-500"),
                    fontSize: "12px",
                    fontFamily: this.layoutConfig("font-family"),
                  },
                },
                crosshairs: {
                  show: false,
                  position: "front",
                  stroke: {
                    color: this.layoutConfig("colors.gray.gray-300"),
                    width: 1,
                    dashArray: 3,
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },
              yaxis: {
                show: false,
                min: 0,
                max: 55,
                labels: {
                  show: false,
                  style: {
                    colors: this.layoutConfig("colors.gray.gray-500"),
                    fontSize: "12px",
                    fontFamily: this.layoutConfig("font-family"),
                  },
                },
              },
              states: {
                normal: {
                  filter: {
                    type: "none",
                    value: 0,
                  },
                },
                hover: {
                  filter: {
                    type: "none",
                    value: 0,
                  },
                },
                active: {
                  allowMultipleDataPointsSelection: false,
                  filter: {
                    type: "none",
                    value: 0,
                  },
                },
              },
              tooltip: {
                style: {
                  fontSize: "12px",
                  fontFamily: this.layoutConfig("font-family"),
                },
                y: {
                  formatter: function (val) {
                    // return "$" + val + " thousands";
                    return val;
                  },
                },
              },
              colors: [this.layoutConfig("colors.theme.light.primary")],
              markers: {
                colors: [this.layoutConfig("colors.theme.light.primary")],
                strokeColor: [this.layoutConfig("colors.theme.base.primary")],
                strokeWidth: 3,
              },
              grid: {
                show: false,
                padding: {
                  left: 0,
                  right: 0,
                },
              },
            };
          }
        })
        .catch(({ response }) => { });
    },
  }
};
</script>
