<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Top 5 Customer Stats</span>
      </h3>
      <!-- <div class="card-toolbar">
        <a class="btn btn-info font-weight-bolder font-size-sm mr-3">New Arrivals</a>
        <a class="btn btn-danger font-weight-bolder font-size-sm">Create</a>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
            <thead>
              <tr class="text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Profile</span>
                </th>
                <th style="min-width: 100px">Shipments</th>
                <th style="min-width: 100px">Total Cost</th>

              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0 pt-8">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="symbol-label">
                          <img :src="item.profile_path" class="h-75 align-self-end" alt="" />
                        </span>
                      </div>
                      <div>
                        <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg" @click="routeToCd(item.id)">{{ item.name
                        }} / {{ item.code }}</a>
                        <span class="text-muted font-weight-bold d-block">{{
                          item.email
                        }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.shipment }}</span>

                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg"> $ {{ item.total }}</span>

                  </td>

                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "widget-3",
  data() {
    return {
      list: [],
    };
  },
  mounted() {
   this.getCustomerTrends();
  },
  methods: {
    getCustomerTrends() {
      ApiService.get("dashboardTopFiveCustomerTrends")
        .then((response) => {
          if (response.data.success) {
            this.list = response.data.trend;

          }
        })
        .catch(({ response }) => { });
    },
    routeToCd(id){
      this.$router.push({
          name: "cdashboard",
          params: {
            id: id,
          },
        });
    }
  }
};
</script>
