<template>
  <div>
    <div v-if="currentUser.role == 6">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <b-img v-bind="imgProps" src="media/users/default.jpg" rounded="circle" alt="Circle image"></b-img>
            <p class="card-label font-weight-normal" v-if="currentUser.type == 1">
              {{ currentUser.first_name }} {{ currentUser.last_name }} (<span class="font-weight-bold">{{
                currentUser.unique_id }}</span>)
            </p>
            <p class="card-label font-weight-normal" v-if="currentUser.type == 2">
              {{ currentUser.business_name }} (<span class="font-weight-bold">{{
                currentUser.unique_id
              }}</span>)
            </p>
          </div>
          <div class="card-toolbar">
            <div class="mt-1">
              <router-link to="/prealert/add"><label class="mr-2 cursor-pointer"><i class="flaticon2-add-1"></i> You Shop,
                  We Ship</label></router-link>
              <router-link to="/prealert/add-shipment"><label class="mr-2 cursor-pointer"><i class="flaticon2-add-1"></i>
                  We Grab, We Ship</label></router-link>
              <router-link to="/prealert/shipments"><label class="cursor-pointer"><i class="flaticon2-cube"></i> My
                  Shipments</label></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">Recent Shipments</h3>
              </div>
            </div>
            <div class="card-body body-fluid">
              <div class="table-responsive">
                <b-table id="shipments-tbl" striped hover :items="shipments" :fields="fields">

                  <template #cell(payment_status)="row">
                    <template v-if="row.item.payment_status == 1">
                      <span class="badge badge-pill badge-info">Paid</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-pill badge-warning">Due</span>
                    </template>
                  </template>
                  <template #cell(tracking_no)="row">
                    <a class="a" @click="getRecord(row.item.id)">{{
                      row.item.tracking_no
                    }}</a>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">Your Shipping Address</h3>
              </div>
            </div>
            <div class="card-body body-fluid">
              <div class="row">
                <div class="col-md-4" v-for="address in addresses" :key="address.id">
                  <p>Name : {{ address.name }} #{{ currentUser.unique_id }}</p>
                  <p>Phone : {{ address.phone }} </p>
                  <p>Address line 1 : {{ address.address_line_1 }}</p>
                  <p>City / State : {{ address.city_state }}</p>
                  <p>
                    Country / Zip : {{ address.country }}
                    <span v-if="address.zip">- {{ address.zip }}</span>
                  </p>
                  <p>Remarks: {{ address.remarks }}</p>
                  <hr />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentUser.role != 6">
      <!--begin::Dashboard-->
      <div class="row" >
        <div class="col-xxl-4">
          <MixedWidget1></MixedWidget1>
        </div>
        <div class="col-xxl-4">
          <ListWidget1></ListWidget1>
        </div>
        <div class="col-xxl-4" v-if="currentUser.role == 1">
          <StatsWidget7></StatsWidget7>
          <StatsWidget12></StatsWidget12>
        </div>


        <div class="col-xxl-12 order-1 order-xxl-2" v-if="currentUser.role == 1">
          <AdvancedTableWidget3></AdvancedTableWidget3>
        </div>
      </div>
      <!--end::Dashboard-->
    </div>
    <span>© 2023 Indobox Asia Pte Ltd. All Rights Reserved.</span>
  </div>
</template>

<style>
p {
  margin-bottom: unset !important;
}

.cursor-pointer {
  cursor: pointer !important;
  color: black !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";

export default {
  name: "dashboard",
  components: {
    AdvancedTableWidget2,
    AdvancedTableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13,
  },
  data() {
    return {
      addresses: [],
      shipments: null,
      fields: [
        {
          key: "tracking_no",
          label: "Tracking No.",
        },

        {
          key: "status",
          label: "Status",
        },

        {
          key: "receiver_city",
          label: "Origin To Destination",
          formatter: (value, key, item) => {

            if (item.type == 1) {
              return item.prealert_sender_city + " To " + value;
            }
            if (item.type == 2) {
              return item.sender_city + " To " + value;
            }
          },
        },

        {
          key: "shipping_mode",
          label: "Mode",
        },

        {
          key: "purchase_date",
          label: "Booking Date",
          formatter: (value, key, item) => {
            return new Date(item.booking_date).toLocaleDateString();
          },
        },
        {
          key: "payment_status",
          label: "Invoice Status",
        },

      ],
      imgProps: {
        width: 25,
        height: 25,
        class: "mr-2",
      },
    };
  },
  created() {
    this.getAllVA();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
    this.getRecentShipments();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    getAllVA() {
      ApiService.get("virtualaddress")
        .then(({ data }) => {
          this.addresses = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getRecentShipments() {
      ApiService.get("dashboardRecentShipments", this.currentUser.id)
        .then((response) => {
          if (response.data.success) {
            this.shipments = response.data.shipments;
          }
        })
        .catch(() => {

        });
    },
    getRecord(id) {
      this.$router.push({
        name: "view-shipment",
        params: {
          id: id,
        },
      });
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
