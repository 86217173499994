<template>
  <!--begin::List Widget 1-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Account Summary</h3>
      <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in list">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 mr-5" v-bind:class="`symbol-light-${item.style}`" v-if="item.show">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-lg" v-bind:class="`svg-icon-${item.style}`">
                <!--begin::Svg Icon-->
                <inline-svg :src="item.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column font-weight-bold" v-if="item.show">
            <a class="text-dark text-hover-primary mb-1 font-size-lg">
              {{ item.title }}
            </a>
            <span class="text-muted">
              {{ item.desc }}
            </span>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 1-->
</template>

<script>
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "widget-1",
  data() {
    return {
      list: [
      ],
    };
  },
  components: {
    Dropdown3,
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      ApiService.get("dashboardsummary")
        .then((response) => {
          if (response.data.success) {
            var data = response.data.summary;
            var role = this.currentUser.role;
            var show = false;
            if (role == 1) {
              show = true;
            }

            
            this.list.push(
              {
                title: "Today's Sale",
                desc: "Payments in SGD " + data.todaySalesTotal,
                svg: "media/svg/icons/Home/Library.svg",
                style: "primary",
                show: show
              }
            );

            this.list.push(
              {
                title: "Month's Sale",
                desc: "Payments in SGD " + data.currentMonthSaleTotal,
                svg: "media/svg/icons/Communication/Write.svg",
                style: "warning",
                show: show
              }
            );

            this.list.push(
              {
                title: "Today's Shipments",
                desc: "Count " + data.todayShipmentCount,
                svg: "media/svg/icons/Communication/Write.svg",
                style: "success",
                show: true
              }
            );

            this.list.push(
              {
                title: "Month's Shipments",
                desc: "Count " + data.monthShipmentCount,
                svg: "media/svg/icons/General/Attachment2.svg",
                style: "info",
                show: true
              }
            );

            this.list.push(
              {
                title: "Total Shipments",
                desc: "Count " + data.totalShipmentCount,
                svg: "media/svg/icons/General/Attachment2.svg",
                style: "info",
                show: true
              }
            );

            this.list.push(
              {
                title: "Total Containers",
                desc: "Count " + data.totalContainerCount,
                svg: "media/svg/icons/General/Attachment2.svg",
                style: "danger",
                show: true
              }
            );
          }
        })
        .catch(({ response }) => { });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["currentUser"]),
  },
};
</script>
